@charset "UTF-8";
:root {
  --color-black: #000;
  --color-white: #fff;
  --color-grey-0: #262626;
  --color-grey-1: #505050;
  --color-grey-2: #505050;
  --color-grey-3: #646771;
  --color-grey-4: #989FB7;
  --color-grey-5: #afb5b8;
  --color-grey-6: #d7d7d7;
  --color-grey-7: #e1e1e1;
  --color-grey-8: #fafafa;
  --color-brand: #3366DD;
  --color-brand-light: #3377EE;
  --color-error: #ee2233;
  --color-valid: #559E00;
  --color-warning: #ffe57b;
  --color-border: var(--color-grey-6);
  --color-border-light: var(--color-grey-6);
  --color-text: var(--color-grey-1);
  --color-text-discreet: var(--color-grey-4);
  --color-bg: var(--color-grey-8);
  --color-link: var(--color-black);
  --color-link-hover: var(--color-grey-4);
  --color-form-bg: var(--color-white);
  --color-form-border: var(--color-grey-4);
  --color-form-border-active: var(--color-grey-2);
  --color-form-label: var(--color-text);
  --color-form-text: var(--color-text);
  --color-form-placeholder: var(--color-grey-2);
  --color-form-disabled: var(--color-grey-3);
  --color-form-valid: transparent;
  --color-form-invalid: var(--color-error); }

:root {
  --h1-mobile: rem(26);
  --h2-mobile: rem(22);
  --h3-mobile: rem(20);
  --h4-mobile: rem(18);
  --h5-mobile: rem(16);
  --h1-desktop: rem(44);
  --h2-desktop: rem(40);
  --h3-desktop: rem(32);
  --h4-desktop: rem(24);
  --h5-desktop: rem(20);
  --base-font-size: 16;
  --article-font-size: 15; }

@font-face {
  font-family: "GT America";
  font-weight: normal;
  src: url("/fonts/GT-America-Standard-Regular.woff2") format("woff2"), url("/fonts/GT-America-Standard-Regular.woff") format("woff"), url("/fonts/GT-America-Standard-Regular.ttf") format("ttf"), url("/fonts/GT-America-Standard-Regular.otf") format("otf"); }

@font-face {
  font-family: "GT America";
  font-weight: bold;
  src: url("/fonts/GT-America-Standard-Bold.woff2") format("woff2"), url("/fonts/GT-America-Standard-Bold.woff") format("woff"), url("/fonts/GT-America-Standard-Bold.ttf") format("ttf"), url("/fonts/GT-America-Standard-Bold.otf") format("otf"); }

@font-face {
  font-family: "GT America";
  font-weight: normal;
  font-style: italic;
  src: url("/fonts/GT-America-Standard-Regular-Italic.woff2") format("woff2"), url("/fonts/GT-America-Standard-Regular-Italic.woff") format("woff"), url("/fonts/GT-America-Standard-Regular-Italic.ttf") format("ttf"), url("/fonts/GT-America-Standard-Regular-Italic.otf") format("otf"); }

@font-face {
  font-family: "GT America";
  font-weight: bold;
  font-style: italic;
  src: url("/fonts/GT-America-Standard-Bold-Italic.woff2") format("woff2"), url("/fonts/GT-America-Standard-Bold-Italic.woff") format("woff"), url("/fonts/GT-America-Standard-Bold-Italic.ttf") format("ttf"), url("/fonts/GT-America-Standard-Bold-Italic.otf") format("otf"); }

:root {
  --font-primary: "GT America", Arial, sans-serif;
  --font-secondary: "GT America", Georgia, Times, 'Times New Roman', serif;
  --font-tertiary: "GT America", Verdana, Helvetica, Arial, sans-serif; }

:root {
  --p-xxs: rem(5);
  --p-xs: rem(10);
  --p-sm: rem(15);
  --p-md: rem(20);
  --p-lg: rem(25);
  --p-xl: rem(30);
  --p-xxl: rem(35);
  --p-xxxl: rem(50);
  --m-xxs: rem(15);
  --m-xs: rem(20);
  --m-sm: rem(30);
  --m-md: rem(40);
  --m-lg: rem(60);
  --m-xl: rem(80);
  --m-xxl: rem(100); }

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  color: #006656;
  background: var(--color-white);
  -webkit-text-size-adjust: none;
  font-family: monospace; }

.hljs-comment,
.diff .hljs-header,
.hljs-javadoc {
  color: #998; }

.hljs-keyword,
.css .rule .hljs-keyword,
.hljs-winutils,
.nginx .hljs-title,
.hljs-subst,
.hljs-request,
.hljs-status {
  color: #1184CE; }

.hljs-number,
.hljs-hexcolor,
.ruby .hljs-constant {
  color: #ed225d; }

.hljs-string,
.hljs-tag .hljs-value,
.hljs-phpdoc,
.hljs-dartdoc,
.tex .hljs-formula {
  color: #ed225d; }

.hljs-title,
.hljs-id,
.scss .hljs-preprocessor {
  color: #900;
  font-weight: bold; }

.hljs-list .hljs-keyword,
.hljs-subst {
  font-weight: normal; }

.hljs-class .hljs-title,
.hljs-type,
.vhdl .hljs-literal,
.tex .hljs-command {
  color: #458;
  font-weight: bold; }

.hljs-tag,
.hljs-tag .hljs-title,
.hljs-rules .hljs-property,
.django .hljs-tag .hljs-keyword {
  color: #006656;
  font-weight: normal; }

.hljs-attribute,
.hljs-variable,
.lisp .hljs-body {
  color: #df5ce4; }

.hljs-regexp {
  color: #009926; }

.hljs-symbol,
.ruby .hljs-symbol .hljs-string,
.lisp .hljs-keyword,
.clojure .hljs-keyword,
.scheme .hljs-keyword,
.tex .hljs-special,
.hljs-prompt {
  color: #990073; }

.hljs-built_in {
  color: #0086b3; }

.hljs-preprocessor,
.hljs-pragma,
.hljs-pi,
.hljs-doctype,
.hljs-shebang,
.hljs-cdata {
  color: #999;
  font-weight: bold; }

.hljs-deletion {
  background: #fdd; }

.hljs-addition {
  background: #dfd; }

.diff .hljs-change {
  background: #0086b3; }

.hljs-chunk {
  color: #aaa; }

.hljs td {
  padding: rem(2) rem(0) rem(2) rem(30) !important; }

.hljs-ln {
  table-layout: auto; }

.hljs-ln tr {
  border-bottom: 0 !important; }

.hljs td.hljs-ln-numbers {
  padding: rem(2) rem(10) rem(2) 0 !important;
  font-style: initial;
  position: relative;
  min-width: rem(40);
  text-align: right;
  font-size: rem(13);
  font-weight: bold;
  color: var(--color-grey-4);
  border-right: rem(5) solid var(--color-grey-6); }

.sh-css-grid {
  display: flex;
  flex-wrap: wrap;
  display: grid;
  margin: 0 rem(-20); }
  .sh-css-grid .sh-css-grid {
    margin: 0;
    display: inherit; }
  .sh-css-grid__cell,
  .sh-css-grid > * {
    margin: rem(40) rem(20) 0;
    display: block; }
  .sh-css-grid.no-gutter {
    margin-left: 0;
    margin-right: 0; }
    .sh-css-grid.no-gutter > * {
      margin-left: 0;
      margin-right: 0; }
  .sh-css-grid img {
    width: 100%; }
  .sh-css-grid + h4,
  .sh-css-grid + p {
    margin-top: rem(40); }
  .sh-css-grid.two {
    grid-template-columns: 1fr 1fr; }
    .sh-css-grid.two > * {
      flex: 0 1 auto;
      width: calc(100% / 2 - 40px); }
      @supports (display: grid) {
        .sh-css-grid.two > * {
          width: auto;
          flex: 1; } }
    .sh-css-grid.two.no-gutter > * {
      width: calc(100% / 2); }
      @supports (display: grid) {
        .sh-css-grid.two.no-gutter > * {
          width: auto; } }
  .sh-css-grid.three {
    grid-template-columns: 1fr 1fr 1fr; }
    .sh-css-grid.three > * {
      flex: 0 1 auto;
      width: calc(100% / 2 - 40px); }
      @supports (display: grid) {
        .sh-css-grid.three > * {
          width: auto;
          flex: 1; } }
    .sh-css-grid.three.no-gutter > * {
      width: 33.33333%; }
      @supports (display: grid) {
        .sh-css-grid.three.no-gutter > * {
          width: auto; } }
  .sh-css-grid.four {
    grid-template-columns: 1fr 1fr 1fr 1fr; }
    .sh-css-grid.four > * {
      width: calc(25% - 40px); }
      @supports (display: grid) {
        .sh-css-grid.four > * {
          width: auto; } }
    .sh-css-grid.four.no-gutter > * {
      width: 25%; }
      @supports (display: grid) {
        .sh-css-grid.four.no-gutter > * {
          width: auto; } }
  @media (min-width: 48em) {
    .sh-css-grid.five {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr; }
      .sh-css-grid.five > * {
        width: calc(20% - 40px); }
        @supports (display: grid) {
          .sh-css-grid.five > * {
            width: auto; } }
      .sh-css-grid.five.no-gutter > * {
        width: 20%; }
        @supports (display: grid) {
          .sh-css-grid.five.no-gutter > * {
            width: auto; } } }
  @media (min-width: 48em) {
    .sh-css-grid.six {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; }
      .sh-css-grid.six > * {
        width: calc(16.66666% - 40px); }
        @supports (display: grid) {
          .sh-css-grid.six > * {
            width: auto; } }
      .sh-css-grid.six.no-gutter > * {
        width: 16.66666%; }
        @supports (display: grid) {
          .sh-css-grid.six.no-gutter > * {
            width: auto; } } }
  @media (min-width: 48em) {
    .sh-css-grid.one-two {
      grid-template-columns: 1fr 2fr; }
      .sh-css-grid.one-two > *:nth-child(odd) {
        width: calc(33.33333% - 40px); }
      .sh-css-grid.one-two > *:nth-child(even) {
        width: calc(66.66666% - 40px); }
      @supports (display: grid) {
        .sh-css-grid.one-two > * {
          width: auto; }
          .sh-css-grid.one-two > *:nth-child(odd), .sh-css-grid.one-two > *:nth-child(even) {
            width: auto; } }
      .sh-css-grid.one-two.no-gutter > *:nth-child(odd) {
        width: 33.33333%; }
      .sh-css-grid.one-two.no-gutter > *:nth-child(even) {
        width: 66.66666%; }
      @supports (display: grid) {
        .sh-css-grid.one-two.no-gutter > * {
          width: auto; }
          .sh-css-grid.one-two.no-gutter > *:nth-child(odd), .sh-css-grid.one-two.no-gutter > *:nth-child(even) {
            width: auto; } } }
  @media (min-width: 48em) {
    .sh-css-grid.two-one {
      grid-template-columns: 2fr 1fr; }
      .sh-css-grid.two-one > * {
        font-size: rem(16); }
        .sh-css-grid.two-one > *:nth-child(odd) {
          width: calc(66.66666% - 40px); }
        .sh-css-grid.two-one > *:nth-child(even) {
          width: calc(33.33333% - 40px); }
        @supports (display: grid) {
          .sh-css-grid.two-one > * {
            width: auto; }
            .sh-css-grid.two-one > *:nth-child(odd), .sh-css-grid.two-one > *:nth-child(even) {
              width: auto; } }
      .sh-css-grid.two-one.no-gutter > * {
        font-size: rem(16); }
        .sh-css-grid.two-one.no-gutter > *:nth-child(odd) {
          width: 66.66666%; }
        .sh-css-grid.two-one.no-gutter > *:nth-child(even) {
          width: 33.33333%; }
        @supports (display: grid) {
          .sh-css-grid.two-one.no-gutter > * {
            width: auto; }
            .sh-css-grid.two-one.no-gutter > *:nth-child(odd), .sh-css-grid.two-one.no-gutter > *:nth-child(even) {
              width: auto; } } }

.text--center {
  text-align: center; }

h1, h2, h3, h4, h5, h6 {
  text-transform: none;
  font-style: normal;
  font-weight: normal; }

:root {
  --main-bg-color: var(--color-white);
  --main-index-page-bg-color: var(--color-brand);
  --main-index-page-text-color: var(--color-white);
  --code-block-bg-color: var(--color-grey-7); }

body.sh-designsystem {
  font-family: var(--font-secondary);
  overflow-y: scroll;
  overflow-x: auto;
  background-color: var(--search-bg-color); }
  body.sh-designsystem *, body.sh-designsystem *:before, body.sh-designsystem *:after {
    box-sizing: border-box; }
  body.sh-designsystem .sh-main-container {
    position: relative;
    height: 100vh;
    z-index: 2; }
  body.sh-designsystem main.sh-main {
    transition: width var(--mm-transition);
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    min-height: 100vh;
    width: calc(100% - var(--mm-width-open));
    padding-bottom: rem(300);
    background-color: var(--main-bg-color); }
    body.sh-designsystem main.sh-main--index-page {
      background: var(--main-index-page-bg-color); }
      body.sh-designsystem main.sh-main--index-page h1, body.sh-designsystem main.sh-main--index-page p {
        color: var(--main-index-page-text-color); }
  body.sh-designsystem .sh-section__header code,
  body.sh-designsystem .sh-module__body code {
    font-family: monospace;
    background-color: var(--code-block-bg-color);
    border-radius: 2px;
    display: inline-block;
    padding: rem(2) rem(4); }
  body.sh-designsystem .sh-module__example .sh-heading-5,
  body.sh-designsystem .sh-module__modifiers .sh-heading-5 {
    font-size: rem(12);
    font-weight: bold;
    padding: rem(2) rem(4);
    margin-left: rem(15);
    background-color: var(--color-white); }
  body.sh-designsystem .sh-module__example .fix-child-fixed > * {
    position: relative !important; }
  body.sh-designsystem .sh-module__modifiers > div {
    padding: rem(5) 0; }
  body.sh-designsystem .sh-module__modifiers .sh-code {
    padding: rem(2) rem(4);
    font-family: monospace;
    background-color: var(--code-block-bg-color);
    border-radius: 2px; }
    body.sh-designsystem .sh-module__modifiers .sh-code + div + p {
      color: #999;
      font-style: italic;
      font-size: rem(12); }
  body.sh-designsystem .is-hidden {
    display: none; }
  body.sh-designsystem .sh-svg-icon {
    width: 1em;
    height: 1em;
    fill: var(--color-black); }
  body.sh-designsystem .sh-t--notice {
    color: var(--color-error);
    font-weight: bold;
    font-size: 110%; }
  body.sh-designsystem .sh-t--highlight {
    color: var(--color-brand);
    font-weight: bold;
    font-size: 120%; }
  body.sh-designsystem hr.sh-hr {
    border-width: 0;
    border-top: rem(1) solid var(--color-grey-5);
    margin-bottom: var(--p-md); }
  body.sh-designsystem .sh-logo-con > .image-holder {
    max-width: rem(157); }
  body.sh-designsystem .sh-link, body.sh-designsystem .sh-expandable__list a, .sh-expandable__list body.sh-designsystem a {
    position: relative;
    text-decoration: none; }
    body.sh-designsystem .sh-link::before, body.sh-designsystem .sh-expandable__list a::before, .sh-expandable__list body.sh-designsystem a::before {
      content: '';
      transition: width .2s;
      width: 0;
      height: rem(1);
      background: var(--mm-text-color-hover);
      position: absolute;
      top: 100%; }
    body.sh-designsystem .sh-link:visited, body.sh-designsystem .sh-expandable__list a:visited, .sh-expandable__list body.sh-designsystem a:visited {
      color: var(--color-link); }
    body.sh-designsystem .sh-link:hover, body.sh-designsystem .sh-expandable__list a:hover, .sh-expandable__list body.sh-designsystem a:hover {
      color: var(--mm-text-color-hover); }
      body.sh-designsystem .sh-link:hover::before, body.sh-designsystem .sh-expandable__list a:hover::before, .sh-expandable__list body.sh-designsystem a:hover::before {
        width: 100%; }
  body.sh-designsystem template {
    display: none; }
  body.sh-designsystem xmp.sh-xmp {
    background-color: var(--color-white);
    padding: 5px;
    white-space: pre-wrap; }
  body.sh-designsystem pre.sh-pre {
    -moz-tab-size: 4; }
  body.sh-designsystem pre.sh-pre {
    tab-size: 4; }
  body.sh-designsystem pre.sh-pre code.sh-code {
    border: 0;
    border-radius: 0;
    background-color: var(--color-white);
    top: auto; }
  body.sh-designsystem figure.sh-template-figure img {
    max-width: 100%; }
  body.sh-designsystem .ex {
    background: tomato; }

.sh-fixed-menu {
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
  background-color: #1f2532;
  color: white;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  transform: translateY(100%); }
  .sh-fixed-menu a {
    color: white;
    cursor: pointer; }
    .sh-fixed-menu a:hover {
      color: white; }
  .sh-fixed-menu.sh-fixed-menu-is-active {
    transform: translateY(0%); }
  .sh-fixed-menu.sh-fixed-menu-is-active .sh-fixed-menu-toggle {
    display: block;
    background-color: #404657; }
  .sh-fixed-menu .sh-fixed-menu-toggle {
    position: absolute;
    bottom: 100%;
    left: 0;
    padding: 3px 8px;
    color: #fff;
    background-color: #1f2532;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 0 3px 0 0;
    transition: background 0.3s; }
    .sh-fixed-menu .sh-fixed-menu-toggle:hover {
      background-color: #404657; }

.sh-fixed-menu-back {
  float: left;
  display: block;
  width: 60px;
  line-height: 43px; }

.sh-fixed-menu-nav > ul.sh-fixed-menu-nav-main {
  text-align: center; }

.sh-fixed-menu-nav::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  overflow: hidden;
  clear: both; }

.sh-fixed-menu-nav > ul.sh-fixed-menu-nav-main > li {
  padding-top: 10px;
  padding-bottom: 10px; }

.sh-fixed-menu-nav ul.sh-fixed-menu-nav-main li {
  display: inline-block; }
  .sh-fixed-menu-nav ul.sh-fixed-menu-nav-main li a {
    color: #9aa2b8;
    font-size: 14px; }
    .sh-fixed-menu-nav ul.sh-fixed-menu-nav-main li a:hover {
      color: white; }
  .sh-fixed-menu-nav ul.sh-fixed-menu-nav-main li a.sh-fixed-menu-current-page {
    color: white;
    cursor: text; }
  .sh-fixed-menu-nav ul.sh-fixed-menu-nav-main li::after {
    content: '|';
    padding: 0 10px;
    display: inline-block;
    font-size: 13px;
    color: #697080; }
  .sh-fixed-menu-nav ul.sh-fixed-menu-nav-main li:last-child:after {
    display: none; }

.sh-fixed-menu-nav > ul.sh-fixed-menu-nav-main > li ul {
  display: block;
  /* HIDE HERE */
  display: none; }

.sh-fixed-menu-nav > ul.sh-fixed-menu-nav-main > li ul:before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #1f2532;
  position: absolute;
  top: 100%;
  margin-top: -10px;
  margin-left: 30px; }

.sh-fixed-menu-nav > ul.sh-fixed-menu-nav-main > li > ul li ul {
  margin-bottom: -10px; }

.sh-fixed-menu-nav > ul.sh-fixed-menu-nav-main > li ul {
  padding: 10px; }

.sh-fixed-menu-nav > ul.sh-fixed-menu-nav-main > li > ul li {
  padding: 3px 0px 7px;
  margin-right: -2px;
  background-color: #1f2532; }

.sh-fixed-menu-nav > ul.sh-fixed-menu-nav-main > li > ul li:first-child {
  padding-left: 13px;
  border-radius: 3px 0 0 3px; }

.sh-fixed-menu-nav > ul.sh-fixed-menu-nav-main > li > ul li:last-child {
  padding-right: 13px;
  border-radius: 0 3px 3px 0; }

.sh-fixed-menu-nav > ul.sh-fixed-menu-nav-main li:hover > ul {
  display: block; }

.sh-fixed-menu-nav ul.sh-fixed-menu-nav-main li > ul {
  position: absolute;
  bottom: 100%;
  white-space: nowrap; }

.sh-fixed-menu-nav-prev-next {
  float: right;
  text-align: right; }
  .sh-fixed-menu-nav-prev-next li {
    display: inline-block;
    padding: 0 15px;
    margin: 10px 0; }
    .sh-fixed-menu-nav-prev-next li::last-child {
      padding-right: 0; }
    .sh-fixed-menu-nav-prev-next li a {
      color: #9aa2b8;
      font-size: 14px; }
    .sh-fixed-menu-nav-prev-next li:hover::after {
      content: attr(data-page);
      display: inline-block;
      padding: 4px 8px;
      white-space: nowrap;
      font-size: 13px;
      color: white;
      border-radius: 3px;
      background-color: #1f2532;
      position: absolute;
      margin-top: -45px;
      transform: translate(-100%, 0); }
  .sh-fixed-menu-nav-prev-next li.sh-fixed-menu-nav-prev a:hover:before,
  .sh-fixed-menu-nav-prev-next li.sh-fixed-menu-nav-next a:hover:after {
    color: white; }
  .sh-fixed-menu-nav-prev-next li.sh-fixed-menu-nav-prev a:before {
    content: '‹';
    padding: 0 10px 0 0;
    display: inline-block;
    font-size: 13px;
    color: #697080; }
  .sh-fixed-menu-nav-prev-next li.sh-fixed-menu-nav-next a:after {
    content: '›';
    padding: 0 0 0 10px;
    display: inline-block;
    font-size: 13px;
    color: #697080;
    transition: color 0.2s; }

:root {
  --mm-width-open: rem(300);
  --mm-width-closed: rem(45);
  --mm-logo-padding: rem(80) rem(20);
  --mm-toggle-duration: .5s;
  --mm-toggle-ease: ease-in-out;
  --mm-transition: var(--mm-toggle-duration) var(--mm-toggle-ease);
  --mm-bg-color: var(--color-white);
  --mm-text-color: var(--color-link);
  --mm-text-color-hover: var(--color-link);
  --mm-right-border: rem(1) solid var(--color-grey-7);
  --mm-vertical-border: var(--mm-right-border);
  --mm-active-item-border-width: rem(3);
  --mm-active-item-border-color: var(--color-link-hover);
  --mm-li-indent: rem(25);
  --mm-first-level-v-padding: rem(25);
  --mm-first-level-font-size: rem(15);
  --mm-first-level-font-weight: bold;
  --mm-sub-item-font-size: rem(13);
  --mm-sub-item-line-height: 2; }

.sh-main-menu {
  transition: transform var(--mm-transition);
  display: block;
  overflow: hidden;
  position: fixed;
  width: var(--mm-width-open);
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--mm-bg-color);
  border-right: var(--mm-right-border); }
  .sh-main-menu a {
    transition: color .2s;
    color: var(--mm-text-color);
    position: relative;
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
    .is-current .sh-main-menu a {
      color: var(--mm-text-color-hover); }
    .sh-main-menu a:visited {
      color: var(--color-link); }
    .sh-main-menu a:hover, .sh-main-menu a:focus {
      color: var(--mm-text-color-hover);
      outline: none; }
      .sh-main-menu a:hover + .sh-svg-toggle path, .sh-main-menu a:hover + .sh-svg-search path, .sh-main-menu a:focus + .sh-svg-toggle path, .sh-main-menu a:focus + .sh-svg-search path {
        fill: var(--mm-text-color-hover); }
    .sh-main-menu a:active {
      color: var(--color-link); }
    .sh-main-menu a.highlight {
      font: bold rem(14)/3.21 var(--font-primary); }
  .sh-main-menu__inner {
    width: 100%;
    height: inherit;
    overflow: auto; }
  .sh-main-menu__logo {
    transition: opacity var(--mm-transition);
    text-align: center; }
    .sh-main-menu__logo img {
      width: 70%; }
  .sh-main-menu__header {
    position: relative;
    padding: var(--mm-logo-padding); }
    .sh-main-menu__header .sh-main-menu__search {
      display: none; }
  .sh-main-menu__burger {
    position: absolute;
    top: rem(4);
    right: rem(4);
    width: rem(36);
    height: rem(36);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: 0;
    outline: 0;
    background: none;
    cursor: pointer; }
    .sh-main-menu__burger__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: rem(20);
      height: rem(20); }
      .sh-main-menu__burger__icon span,
      .sh-main-menu__burger__icon span:after,
      .sh-main-menu__burger__icon span:before {
        transition: all var(--mm-transition);
        width: 100%;
        height: 2px;
        background-color: rgba(0, 0, 0, 0.8);
        backface-visibility: hidden; }
      .sh-main-menu__burger__icon span {
        position: relative;
        display: block;
        background-color: transparent; }
      .sh-main-menu__burger__icon span:after,
      .sh-main-menu__burger__icon span:before {
        content: "";
        position: absolute;
        left: 0; }
      .sh-main-menu__burger__icon span:before {
        transform: rotate(45deg); }
      .sh-main-menu__burger__icon span:after {
        transform: rotate(-45deg); }
  .sh-main-menu__navigation {
    transition: opacity var(--mm-transition); }
    .sh-main-menu__navigation .sh-svg-toggle, .sh-main-menu__navigation .sh-svg-search {
      position: absolute;
      right: rem(12);
      top: rem(21);
      width: rem(24);
      height: rem(24);
      transform: rotate(-90deg);
      pointer-events: none; }
      .sh-main-menu__navigation .sh-svg-toggle path, .sh-main-menu__navigation .sh-svg-search path {
        transition: fill .2s;
        fill: var(--color-black); }
    .sh-main-menu__navigation .sh-svg-search {
      transform: scale(0.7); }
    .sh-main-menu__navigation li {
      padding: 0 0 0 var(--mm-li-indent); }
      .sh-main-menu__navigation li.sh-submenu-toggled > ul {
        display: block; }
      .sh-main-menu__navigation li.sh-submenu-toggled > .sh-svg-toggle {
        transform: rotate(0deg); }
    .sh-main-menu__navigation .first-level > li {
      position: relative;
      border-bottom: var(--mm-vertical-border); }
      .sh-main-menu__navigation .first-level > li:first-child {
        border-top: var(--mm-vertical-border); }
      .sh-main-menu__navigation .first-level > li > a {
        display: block;
        font-size: var(--mm-first-level-font-size);
        font-weight: var(--mm-first-level-font-weight);
        padding: var(--mm-first-level-v-padding) 0; }
      .sh-main-menu__navigation .first-level > li.sh-submenu-toggled > a {
        padding: var(--mm-first-level-v-padding) 0 var(--mm-sub-item-font-size); }
    .sh-main-menu__navigation .second-level,
    .sh-main-menu__navigation .third-level {
      font-size: var(--mm-sub-item-font-size);
      display: none; }
      .sh-main-menu__navigation .second-level.is-open,
      .sh-main-menu__navigation .third-level.is-open {
        display: block; }
      .sh-main-menu__navigation .second-level li,
      .sh-main-menu__navigation .third-level li {
        position: relative; }
        .sh-main-menu__navigation .second-level li a,
        .sh-main-menu__navigation .third-level li a {
          display: block;
          line-height: var(--mm-sub-item-line-height); }
    .sh-main-menu__navigation .second-level {
      margin-bottom: var(--mm-first-level-v-padding); }
      .sh-main-menu__navigation .second-level .sh-svg-toggle {
        top: rem(2);
        height: rem(18); }
      .sh-main-menu__navigation .second-level .is-current:not(.is-open) > a {
        position: relative;
        font-weight: bold; }
        .sh-main-menu__navigation .second-level .is-current:not(.is-open) > a:before {
          content: "";
          position: absolute;
          left: calc(var(--mm-li-indent) * -2);
          width: var(--mm-active-item-border-width);
          height: 100%;
          background-color: var(--mm-active-item-border-color); }
    .sh-main-menu__navigation .third-level .is-current:not(.is-open) > a {
      position: relative;
      font-weight: bold; }
      .sh-main-menu__navigation .third-level .is-current:not(.is-open) > a:before {
        content: "";
        position: absolute;
        left: calc(var(--mm-li-indent) * -3);
        width: var(--mm-active-item-border-width);
        height: 100%;
        background-color: var(--mm-active-item-border-color); }

.mobile-menu-closed {
  transform: translateX(calc(-100% + var(--mm-width-closed))); }
  .mobile-menu-closed + main {
    width: calc(100% - var(--mm-width-closed)) !important; }
  .mobile-menu-closed .sh-main-menu__inner {
    overflow: hidden; }
  .mobile-menu-closed .sh-main-menu__navigation,
  .mobile-menu-closed .sh-main-menu__logo {
    opacity: 0; }
  .mobile-menu-closed .sh-main-menu__burger__icon span {
    background-color: rgba(0, 0, 0, 0.8); }
  .mobile-menu-closed .sh-main-menu__burger__icon span:before {
    transform: translateY(-6px); }
  .mobile-menu-closed .sh-main-menu__burger__icon span:after {
    transform: translateY(6px); }

.mobile-menu-closed .sh-main-menu {
  transform: translateX(calc(-100% + var(--mm-width-closed))); }
  .mobile-menu-closed .sh-main-menu .sh-main-menu__inner {
    overflow: hidden; }

.mobile-menu-closed > main {
  width: calc(100% - var(--mm-width-closed)) !important; }

.mobile-menu-closed .sh-main-menu__navigation,
.mobile-menu-closed .sh-main-menu__logo {
  opacity: 0; }

.mobile-menu-closed .sh-main-menu__burger__icon span {
  background-color: rgba(0, 0, 0, 0.8); }

.mobile-menu-closed .sh-main-menu__burger__icon span:before {
  transform: translateY(-6px); }

.mobile-menu-closed .sh-main-menu__burger__icon span:after {
  transform: translateY(6px); }

/*
Used for presenting UI modules
*/
:root {
  --module-padding-tb: rem(40);
  --module-margin-tb: rem(40); }

.sh-module:first-child .sh-section__header {
  margin-bottom: rem(120); }

.sh-module__body {
  display: block;
  margin-bottom: rem(10); }
  .sh-module__body p {
    font-size: rem(16); }
  .sh-expandable .sh-module__body {
    margin-bottom: 0; }
  .sh-module__body__disclaimer {
    font-size: rem(14);
    color: #666;
    font-style: italic;
    margin-top: rem(10); }

.sh-module__example {
  position: relative;
  display: block;
  margin: var(--module-margin-tb) 0;
  padding: var(--module-padding-tb) 0; }
  .sh-module__example:before, .sh-module__example:after {
    content: '~';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: rem(200);
    text-align: center;
    font-size: rem(35);
    color: #d4d4d4; }
  .sh-module__example:after {
    top: auto;
    bottom: 0; }
  .sh-module__example.has-modifiers {
    border-top: rem(1) solid var(--color-border); }
    .sh-module__example.has-modifiers:before, .sh-module__example.has-modifiers:after {
      display: none; }

.sh-module__links {
  position: relative; }
  .sh-module__links a {
    display: inline-block;
    font-size: rem(14);
    text-decoration: none;
    margin: rem(3) 0;
    vertical-align: top; }
    .sh-module__links a:hover {
      text-decoration: underline; }
    .sh-module__links a .sh-svg-icon {
      display: inline-block;
      margin-right: rem(5);
      margin-top: rem(2);
      width: rem(12);
      height: rem(12);
      transform: rotate(-90deg);
      fill: var(--color-brand); }
  .sh-section__header .sh-module__links {
    margin-top: rem(30); }
    @media (min-width: 48em) {
      .sh-section__header .sh-module__links {
        margin-left: rem(75); } }
    .sh-section__header .sh-module__links a {
      font-size: rem(16); }
      .sh-section__header .sh-module__links a .sh-svg-icon {
        width: rem(14);
        height: rem(14); }

.sh-module__parameters {
  display: flex;
  flex-wrap: nowrap; }
  .sh-module__parameters > dt,
  .sh-module__parameters > dd {
    padding: rem(10); }
  .sh-module__parameters > dt {
    font-weight: bold;
    padding-left: 0; }
  .sh-module__parameters-default {
    display: block;
    font-size: rem(12);
    font-weight: bold; }

.sh-module__modifiers {
  border-top: rem(1) solid var(--color-border);
  padding-top: rem(20);
  margin-top: rem(20);
  margin-bottom: rem(30); }
  .sh-module__modifiers > div {
    position: relative;
    margin-bottom: rem(10); }
    .sh-module__modifiers > div > div {
      position: relative;
      display: block; }
  .sh-module__modifiers code.sh-code {
    font-size: rem(11);
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1; }
  .sh-module__modifiers--grid {
    margin-bottom: 0;
    padding: rem(40);
    background: var(--color-grey-7);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: rem(15); }
    .sh-module__modifiers--grid > div.sh-module__modifier {
      padding: 0; }
    .sh-module__modifiers--grid p {
      color: var(--color-text); }
    .sh-module__modifiers--grid code {
      position: static; }
    .sh-module__modifiers--grid div {
      min-height: rem(200);
      padding: 0; }

.sh-module__modifier p.sh-p {
  font-size: rem(13);
  line-height: 1.75;
  color: var(--color-text-discreet);
  display: block;
  margin-top: 0.25em; }

.sh-module__modifier .sh-module__modifier__desc {
  opacity: 0;
  transition: opacity .25s ease; }

.sh-module__modifier:hover .sh-module__modifier__desc {
  opacity: 1; }

.sh-module .styleguide-color {
  display: block;
  padding: rem(20);
  color: var(--color-white);
  text-shadow: 0 0 rem(3) black; }

/*
Container for both Contentful and UI modules
*/
:root {
  --section-max-width: rem(1400);
  --section-mobile-h-padding: rem(15);
  --section-tablet-h-padding: rem(45);
  --section-desktop-h-padding: rem(100);
  --section-h2-size: rem(24);
  --section-h2-weight: bold;
  --section-h2-margin-top: 0; }

.sh-section {
  flex: 100% 1 1; }
  .sh-section__wrapper {
    max-width: var(--section-max-width);
    margin: 0 auto;
    padding: rem(20) var(--section-mobile-h-padding);
    position: relative;
    line-height: 1.6; }
    .sh-section__wrapper:first-of-type {
      padding-top: rem(40); }
    @media (min-width: 64em) {
      .sh-section__wrapper {
        padding: rem(25) var(--section-tablet-h-padding); }
        .sh-section__wrapper:first-of-type {
          padding-top: rem(50); } }
    @media (min-width: 95.625em) {
      .sh-section__wrapper {
        padding: rem(30) var(--section-desktop-h-padding); }
        .sh-section__wrapper:first-of-type {
          padding-top: rem(80); } }
  .sh-section__border {
    width: calc(100% - 2 * var(--section-mobile-h-padding));
    max-width: calc(var(--section-max-width) - 2 * var(--section-mobile-h-padding));
    margin: 0 auto;
    box-sizing: border-box;
    border: none;
    border-bottom: rem(1) solid var(--color-border); }
    @media (min-width: 64em) {
      .sh-section__border {
        width: calc(100% - 2 * var(--section-tablet-h-padding));
        max-width: calc(var(--section-max-width) - 2 * var(--section-tablet-h-padding)); } }
    @media (min-width: 95.625em) {
      .sh-section__border {
        width: calc(100% - 2 * var(--section-desktop-h-padding));
        max-width: calc(var(--section-max-width) - 2 * var(--section-desktop-h-padding)); } }
  .sh-section--text-center {
    text-align: center; }
  .sh-section h2.sh-heading-2 {
    font-size: var(--section-h2-size);
    font-weight: var(--section-h2-weight);
    margin-top: var(--section-h2-margin-top); }
  .sh-section .sh-content-wrapper p + p {
    margin-top: rem(20); }

.sh-teaser {
  display: flex;
  flex-direction: column; }
  .sh-teaser--image-right {
    flex-direction: row-reverse; }
    .sh-teaser--image-right > div {
      width: 50%; }
      .sh-teaser--image-right > div:first-of-type {
        padding-left: rem(20);
        margin: 0; }
      .sh-teaser--image-right > div:last-of-type {
        padding-right: rem(20); }
  .sh-teaser--image-bottom {
    flex-direction: column-reverse; }
  .sh-teaser--image-left {
    flex-direction: row; }
    .sh-teaser--image-left > div {
      width: 50%; }
      .sh-teaser--image-left > div:first-of-type {
        padding-right: rem(20);
        margin: 0; }
      .sh-teaser--image-left > div:last-of-type {
        padding-left: rem(20); }
  .sh-teaser .sh-image-holder {
    position: relative;
    display: flex;
    align-items: flex-start;
    margin-bottom: rem(16); }
  .sh-teaser.text--center .sh-image-holder {
    justify-content: center; }

:root {
  --search-bg-color: var(--color-grey-8); }

.sh-main-container {
  transition: transform .5s ease-in-out; }
  .sh-main-container.search-active {
    transform: perspective(300px) translate3d(70vw, 0, 0) rotate3d(0, 1, 0, -15deg);
    overflow: hidden; }
    @media (min-width: 64em) {
      .sh-main-container.search-active {
        transform: perspective(1000px) translate3d(50vw, 0, 0) rotate3d(0, 1, 0, -15deg); } }
    .sh-main-container.search-active:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }

.sh-search-modal {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 70vw;
  height: 100vh;
  background-color: var(--search-bg-color);
  z-index: -1; }
  @media (min-width: 48em) {
    .sh-search-modal {
      width: 50vw; } }
  .sh-search-modal.pre-active {
    display: block;
    transform: scale(1);
    transition: transform .25s ease, opacity .25s ease;
    opacity: 1; }
  .sh-search-modal.is-active {
    transform: scale(1);
    opacity: 1; }
  .sh-search-modal.post-active {
    z-index: 0; }
  .sh-search-modal .sh-search-container {
    position: relative;
    padding: 0 rem(20) rem(120);
    margin: 10vh auto; }
    @media (min-width: 80em) {
      .sh-search-modal .sh-search-container {
        max-width: 80%;
        padding: 0 rem(40) rem(120); } }
    .sh-search-modal .sh-search-container input {
      width: 100%;
      outline: 0;
      border: 0;
      background-color: transparent;
      font-size: 12vw;
      font-weight: bold;
      font-family: var(--font-primary); }
      @media (max-width: 47.99em) {
        .sh-search-modal .sh-search-container input {
          padding: 0;
          border-radius: 0; } }
      @media (min-width: 48em) {
        .sh-search-modal .sh-search-container input {
          font-size: 5vw; } }
  .sh-search-modal .sh-search-result-category-template,
  .sh-search-modal .sh-search-result-template {
    display: none; }
  .sh-search-modal .sh-search-results-container {
    margin-top: rem(10);
    overflow: auto;
    max-height: 90vh; }
    .sh-search-modal .sh-search-results-container .sh-search-results {
      margin-top: rem(40); }
      @media (min-width: 48em) {
        .sh-search-modal .sh-search-results-container .sh-search-results {
          display: block;
          vertical-align: top; } }
      .sh-search-modal .sh-search-results-container .sh-search-results .sh-search-results-heading {
        margin: rem(20) 0 rem(10);
        font-weight: bold;
        text-transform: uppercase;
        font-size: rem(14);
        color: var(--color-brand); }
        .sh-search-modal .sh-search-results-container .sh-search-results .sh-search-results-heading:first-child {
          margin-top: rem(10); }
      .sh-search-modal .sh-search-results-container .sh-search-results .sh-search-result {
        margin-bottom: 1vh; }
        .sh-search-modal .sh-search-results-container .sh-search-results .sh-search-result a {
          display: block;
          background-color: #e6e6e6;
          color: #222;
          text-decoration: none;
          padding: 1vh 3vw;
          transition: background-color .2s ease, color .2s ease;
          border-left: rem(3) solid transparent; }
          @media (min-width: 48em) {
            .sh-search-modal .sh-search-results-container .sh-search-results .sh-search-result a {
              padding: 1vh 1.4vw; } }
          .sh-search-modal .sh-search-results-container .sh-search-results .sh-search-result a:hover {
            background-color: #e1e1e1;
            color: var(--color-brand); }
          .sh-search-modal .sh-search-results-container .sh-search-results .sh-search-result a:focus {
            outline: 0;
            background-color: #e1e1e1;
            border-left: rem(3) solid var(--color-brand); }
    .sh-search-modal .sh-search-results-container.no-results {
      display: none; }
      .sh-search-modal .sh-search-results-container.no-results.is-active {
        display: block; }
  .sh-search-modal .sh-close-icon {
    display: block;
    position: absolute;
    top: -6vh;
    right: rem(40);
    width: rem(25);
    height: rem(25);
    transform: rotate(45deg);
    text-decoration: none; }
    @media (min-width: 48em) {
      .sh-search-modal .sh-close-icon {
        width: rem(50);
        height: rem(50); } }
    .sh-search-modal .sh-close-icon:before, .sh-search-modal .sh-close-icon:after {
      content: '';
      position: absolute;
      top: rem(12);
      left: 0;
      width: rem(25);
      height: rem(2);
      background-color: var(--color-grey-3); }
      @media (min-width: 48em) {
        .sh-search-modal .sh-close-icon:before, .sh-search-modal .sh-close-icon:after {
          width: rem(50); } }
    .sh-search-modal .sh-close-icon:after {
      transform: rotate(90deg); }

:root {
  --ph-h1-mobile-size: rem(35);
  --ph-h1-tablet-size: rem(43);
  --ph-h1-desktop-size: rem(60);
  --ph-h1-weight: bold;
  --ph-h1-color: var(--color-black);
  --ph-p-size: rem(19);
  --ph-p-line-height: 1.5;
  --ph-p-color: var(--color-black); }

.sh-section__page-header {
  position: relative;
  margin-bottom: rem(30);
  flex: 100% 1 1; }
  .sh-section__page-header p {
    font-size: var(--ph-p-size);
    line-height: var(--ph-p-line-height);
    color: var(--ph-p-color); }
  .sh-section__page-header.text--center {
    flex: auto 1 1; }
  .sh-section__page-header ul {
    list-style: disc inside;
    margin-top: rem(15); }
    @media (min-width: 48em) {
      .sh-section__page-header ul {
        padding-left: rem(95); } }
  .sh-section__page-header h1.sh-heading-1 {
    font-size: var(--ph-h1-mobile-size);
    font-weight: var(--ph-h1-weight);
    color: var(--ph-h1-color);
    margin-bottom: rem(20);
    margin-top: 0; }
    .sh-section__page-header h1.sh-heading-1 > img {
      width: .8em;
      margin-right: 20px; }
    @media (min-width: 48em) {
      .sh-section__page-header h1.sh-heading-1 {
        font-size: var(--ph-h1-tablet-size); } }
    @media (min-width: 64em) {
      .sh-section__page-header h1.sh-heading-1 {
        font-size: var(--ph-h1-desktop-size); } }
  .sh-section__page-header h2.sh-heading-2,
  .sh-section__page-header h2.sh-heading-2 a {
    font-size: rem(20);
    color: var(--color-black);
    font-weight: 700;
    margin-bottom: rem(40);
    text-decoration: none; }
    @media (min-width: 48em) {
      .sh-section__page-header h2.sh-heading-2,
      .sh-section__page-header h2.sh-heading-2 a {
        font-size: rem(25); } }
  .sh-section__page-header--subordinate {
    border-bottom: 0;
    padding-bottom: 0;
    padding-top: rem(20); }
    .sh-section__page-header--subordinate p {
      position: absolute;
      top: 0;
      right: 0;
      font-size: rem(12);
      color: var(--color-text-discreet); }
      .sh-section__page-header--subordinate p em,
      .sh-section__page-header--subordinate p span.wip {
        color: var(--color-error); }
      .sh-section__page-header--subordinate p span,
      .sh-section__page-header--subordinate p span.done {
        color: var(--color-valid); }
      .sh-section__page-header--subordinate p span.not-used {
        color: orange; }
  .sh-section__page-header .sh-css-grid {
    margin-bottom: rem(20); }
  .sh-section__page-header p > code {
    display: inline;
    padding: rem(5); }

.sh-media-hero {
  position: relative;
  display: flex;
  justify-content: center; }
  .sh-media-hero .sh-section {
    position: relative;
    z-index: 1; }
  .sh-media-hero__media {
    position: relative;
    width: 100%;
    display: grid;
    overflow: hidden; }
    .sh-media-hero__media * {
      width: 100%;
      object-fit: cover; }
    .sh-section + .sh-media-hero__media {
      position: absolute; }
  .sh-media-hero.text--white h1.sh-heading-1, .sh-media-hero.text--white p {
    color: white; }

.sh-rich-text {
  font-family: var(--font-primary); }
  .sh-rich-text h1 {
    font-size: rem(20);
    font-weight: bold;
    margin: rem(5) 0; }
    .sh-rich-text h1:first-child {
      margin-top: 0; }
  .sh-rich-text h2 {
    font-size: rem(18);
    font-weight: bold;
    margin: rem(5) 0; }
  .sh-rich-text h3 {
    font-size: rem(16);
    font-weight: bold;
    margin: rem(5) 0; }
  .sh-rich-text a {
    text-decoration: underline; }
  .sh-rich-text p {
    max-width: rem(700);
    margin-bottom: rem(15); }
  .sh-rich-text ul,
  .sh-rich-text ol {
    list-style: disc inside;
    padding-left: rem(25);
    margin-bottom: rem(15); }
  .sh-rich-text ol {
    list-style-type: decimal; }
  .sh-rich-text strong {
    font-weight: bold; }
  .sh-rich-text em {
    font-style: italic; }
  .sh-rich-text code {
    display: inline-block;
    padding: rem(3) rem(8); }
  .sh-rich-text table {
    width: 100%;
    max-width: rem(750);
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    line-height: 1.25; }
    .sh-rich-text table th td {
      font-size: rem(14);
      font-weight: 600;
      text-align: left;
      line-height: 1.25;
      padding: 1rem 3rem 1rem 0;
      border-bottom: 2px solid #3057D5;
      white-space: nowrap; }
    .sh-rich-text table tbody tr {
      border-bottom: 1px solid var(--color-grey-1); }
      .sh-rich-text table tbody tr td {
        font-size: rem(14);
        padding: 1rem 3rem 1rem 0;
        line-height: 1.25; }
        .sh-rich-text table tbody tr td:first-child {
          font-weight: 600;
          white-space: nowrap; }

.sh-expandable {
  background-color: var(--color-white);
  padding: 0;
  margin-bottom: rem(30); }
  .sh-expandable__header {
    border-bottom: rem(1) solid var(--color-border); }
  .sh-expandable__list > li {
    display: inline-block;
    padding: rem(10) rem(13);
    margin-right: rem(8);
    font-weight: bold;
    font-size: rem(13); }
    .sh-expandable__list > li:not(:first-child) {
      border-left: rem(1) solid var(--color-border); }
  .sh-expandable__list a {
    display: inline-block;
    font-weight: normal; }
  .sh-expandable__trigger {
    position: relative;
    cursor: pointer;
    padding: rem(13);
    background-color: #f5f5f5;
    display: flex;
    align-items: center; }
    .sh-expandable__trigger > * {
      display: inline-block;
      vertical-align: middle; }
    .sh-expandable__trigger h5.sh-heading-5 {
      font-size: rem(14);
      color: var(--color-text-discreet);
      margin: 0; }
    .sh-expandable__trigger .sh-icon {
      transform-origin: center;
      transition: all 0.1s ease-out;
      width: rem(16);
      height: rem(16);
      margin-left: rem(8);
      display: flex;
      align-items: center; }
      .sh-expandable__trigger .sh-icon .sh-svg-icon {
        width: rem(16);
        height: rem(16);
        transition: fill 0.2s ease-out;
        fill: var(--color-text-discreet); }
    .sh-expandable__trigger:hover .sh-svg-icon {
      fill: var(--color-brand-light); }
  .sh-expandable__content {
    transition: all 0.1s ease-out;
    padding: 0 rem(10);
    overflow: hidden;
    max-height: 0; }
    .sh-expandable__content .sh-code table {
      table-layout: auto;
      width: inherit; }
  .sh-expandable.is-active .sh-expandable__trigger .sh-icon {
    transform: rotate(180deg); }
  .sh-expandable.is-active .sh-expandable__content {
    padding-top: rem(20);
    padding-bottom: rem(20); }
  .sh-expandable--code {
    position: relative;
    border: rem(1) solid var(--color-border); }
    .sh-expandable--code .sh-expandable__trigger {
      transition: background-color .25s ease;
      text-align: right;
      border-top: rem(1) solid var(--color-border);
      justify-content: flex-end; }
      .sh-expandable--code .sh-expandable__trigger:hover {
        background-color: var(--color-bg); }
    .sh-expandable--code .sh-expandable__content {
      padding-top: rem(20);
      padding-bottom: rem(20); }
    .sh-expandable--code code.sh-code {
      position: relative;
      margin: rem(-3) 0;
      padding: rem(2) rem(7);
      background-color: var(--color-white);
      font-size: rem(12);
      color: var(--color-black);
      line-height: 1.1; }
  .sh-expandable--dev-notes {
    border: rem(1) solid var(--color-grey-0); }
    .sh-expandable--dev-notes.sh-expandable {
      padding: 0;
      border-top: 0; }
      .sh-expandable--dev-notes.sh-expandable.is-active .sh-expandable__content {
        padding-top: rem(30);
        padding-bottom: rem(30); }
    .sh-expandable--dev-notes .sh-expandable__trigger {
      transition: background-color .25s ease;
      background-color: var(--color-grey-0);
      padding: rem(10) rem(30);
      font-weight: bold;
      color: var(--color-white);
      text-transform: uppercase; }
      .sh-expandable--dev-notes .sh-expandable__trigger:hover {
        background-color: var(--color-black); }
      .sh-expandable--dev-notes .sh-expandable__trigger h5.sh-heading-5 {
        color: var(--color-white); }
      .sh-expandable--dev-notes .sh-expandable__trigger .sh-icon .sh-svg-icon {
        fill: var(--color-white); }
    .sh-expandable--dev-notes .sh-expandable__content {
      padding-left: rem(30);
      padding-right: rem(30); }
  .sh-expandable .sh-copy-code {
    position: absolute;
    top: rem(42);
    right: 0;
    z-index: 1;
    background: none;
    border: 0;
    font-size: 0;
    padding: .5rem;
    cursor: pointer; }
    .sh-expandable .sh-copy-code:focus {
      outline: none; }
    .sh-expandable .sh-copy-code > .sh-svg-icon {
      fill: var(--color-grey-7);
      font-size: .75rem; }

:root {
  --color-copy-font-family: var(--font-primary);
  --color-copy-title-size: rem(14);
  --color-copy-code-size: var(--color-copy-title-size);
  --color-copy-desc-size: rem(12); }

.sh-color {
  line-height: 1.6; }
  .sh-color * {
    font-family: var(--color-copy-font-family); }
  .sh-color__sample {
    position: relative;
    width: 100%;
    padding-bottom: 100%; }
    .sh-color__sample:hover ul {
      opacity: 1; }
    .sh-color__sample ul {
      transition: opacity .3s ease;
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: center; }
      .sh-color__sample ul li {
        height: 50%;
        padding: 0; }
        .sh-color__sample ul li a {
          transition: background-color .3s ease, color .3s ease;
          position: relative;
          width: 100%;
          height: 100%;
          font-size: rem(12);
          color: rgba(38, 38, 38, 0.6);
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgba(255, 255, 255, 0.9); }
          .sh-color__sample ul li a:hover {
            background-color: rgba(255, 255, 255, 0.6);
            color: #262626; }
    .sh-color__sample--border {
      box-shadow: inset 0px 0px 0px 1px var(--color-grey-6); }
      .sh-color__sample--border ul li a {
        background-color: rgba(0, 0, 0, 0.025); }
        .sh-color__sample--border ul li a:hover {
          background-color: rgba(0, 0, 0, 0.1); }
  .sh-color__title {
    font-size: var(--color-copy-title-size);
    font-weight: bold;
    margin: rem(30) 0 rem(10); }
  .sh-color__code {
    font-size: var(--color-copy-code-size);
    opacity: .5; }
  .sh-color__description {
    font-size: var(--color-copy-desc-size);
    opacity: .5;
    margin-top: rem(10); }

@keyframes moveArrow {
  50% {
    opacity: 0;
    transform: translateY(15%); }
  51% {
    opacity: 0;
    transform: translateY(-25%); } }

:root {
  --rd-bg-color: var(--color-grey-0);
  --rd-text-color: var(--color-white);
  --rd-h2-font: var(--font-primary); }

.sh-resource-download {
  background-color: var(--rd-bg-color);
  color: var(--rd-text-color);
  padding: rem(32); }
  .sh-resource-download h2 {
    margin-bottom: rem(16);
    font-family: var(--rd-h2-font);
    font-weight: bold; }
  .sh-resource-download a {
    display: flex;
    align-items: center;
    width: fit-content;
    font-size: rem(13);
    color: var(--rd-text-color); }
    .sh-resource-download a svg {
      width: 1.2em;
      height: 1.2em;
      margin-right: 0.5em;
      fill: currentColor; }
    .sh-resource-download a:hover svg path:last-of-type {
      animation: moveArrow .5s forwards linear; }

:root {
  --il-font-family: var(--font-primary); }

.sh-icon-library__grid {
  display: flex;
  flex-wrap: wrap;
  margin: -2.5%; }
  @media (min-width: 64em) {
    .sh-icon-library__grid {
      margin: -1.25%; } }
  .sh-icon-library__grid__item {
    width: 20%;
    margin: 2.5%; }
    @media (min-width: 64em) {
      .sh-icon-library__grid__item {
        width: 10%;
        margin: 1.25%; } }
    .sh-icon-library__grid__item a {
      position: relative;
      display: block;
      height: 0;
      padding-bottom: 100%;
      margin-top: rem(30);
      box-shadow: inset 0 0 0 1px var(--color-grey-7);
      font-family: var(--il-font-family); }
      .sh-icon-library__grid__item a:hover img {
        opacity: .05; }
      .sh-icon-library__grid__item a:hover .download-text {
        opacity: 1; }
      .sh-icon-library__grid__item a img {
        transition: opacity .2s ease;
        position: absolute;
        padding: 30%;
        height: 100%;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        filter: invert(1) grayscale(1) brightness(100); }
        .sh-icon-library__grid__item a img.black {
          filter: grayscale(1) brightness(0); }
      .sh-icon-library__grid__item a .download-text {
        transition: opacity .2s ease;
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        font-size: rem(12);
        color: var(--color-black); }
    .sh-icon-library__grid__item h3 {
      font-family: var(--il-font-family);
      font-size: rem(10);
      margin: rem(15) 0 rem(10); }

.sh-font input {
  padding: 0 .05em;
  border: none;
  resize: none;
  width: 100%;
  display: block; }
  .sh-font input:focus {
    outline: none; }

.sh-font__variants {
  display: flex;
  flex-wrap: wrap; }
  .sh-font__variants__item {
    font-size: 4vw;
    margin: .2em 0; }

.sh-font__name, .sh-font__number {
  font-size: 8vw;
  margin: .2em 0; }

.sh-font--normal {
  width: 40%; }

.sh-font--italic {
  width: 60%; }
  .sh-font--italic * {
    font-style: italic; }

.tooltip-active::before {
  content: 'Copied!';
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  padding: rem(5) rem(10);
  display: block;
  background: var(--color-black);
  font-size: rem(11);
  color: var(--color-white); }

.sh-image-tile {
  padding: rem(50) rem(100); }
  .sh-image-tile__title {
    font-size: rem(32);
    margin-bottom: rem(25); }
  .sh-image-tile__content {
    display: flex;
    justify-content: space-between; }
  .sh-image-tile__image {
    position: relative;
    width: 50%;
    padding-bottom: calc(50% / 16 * 9);
    overflow: hidden; }
    .sh-image-tile__image img {
      position: absolute;
      width: auto;
      height: auto;
      min-height: 100%;
      min-width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
  .sh-image-tile__link {
    position: relative;
    display: flex;
    align-items: center;
    font-size: rem(22);
    text-decoration: none;
    color: black;
    padding: rem(10);
    margin-top: rem(25); }
    .sh-image-tile__link:after {
      content: "";
      border-bottom: 1px solid black;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0; }
    .sh-image-tile__link svg {
      transition: transform .6s;
      height: rem(18);
      padding-left: rem(20); }
    .sh-image-tile__link:hover svg {
      transform: translateX(rem(10)); }
  .sh-image-tile.inverted .sh-image-tile__content {
    flex-direction: row-reverse; }
  .sh-image-tile .sh-rich-text {
    width: 40%;
    line-height: 2; }
    .sh-image-tile .sh-rich-text p {
      max-width: 25em; }

:root {
  --font-primary: "Lato", Arial, sans-serif;
  --font-secondary: "Lato", Arial, sans-serif;
  --font-tertiary: "GT America", Verdana, Helvetica, Arial, sans-serif;
  --color-brand: #A3988B;
  --color-brand-light: #A3988B;
  --mm-width-open: rem(350);
  --mm-bg-color: #FAFAFA;
  --mm-text-color-hover: var(--color-brand);
  --mm-active-item-border-color: var(--mm-text-color-hover);
  --mm-right-border: none;
  --mm-vertical-border: rem(1) solid var(--color-grey-7);
  --mm-toggle-ease: cubic-bezier(.25, 0, .25, 1);
  --mm-first-level-font-weight: normal;
  --main-index-page-bg-color: white;
  --main-index-page-text-color: black;
  --ph-h1-weight: normal; }

body {
  font-size: 16px !important; }

.sh-main--index-page {
  padding-bottom: 0 !important; }
  .sh-main--index-page > section .sh-section__wrapper {
    padding: 0;
    max-width: none; }
  .sh-main--index-page .sh-image-tile {
    margin: 0; }
  .sh-main--index-page .sh-css-grid.one {
    margin: 0; }

.sh-main-menu *,
.sh-section__page-header p {
  font-family: var(--font-primary); }

.text--center .sh-teaser .sh-image-holder {
  justify-content: center; }

.text--center .sh-section__page-header p {
  width: 100%; }

.sh-rich-text code {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  font-family: monospace; }

.sh-rich-text strong {
  font-weight: bold; }

p.sh-trumpet-1 {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 0.8rem !important;
  color: #262626;
  font-weight: 600;
  font-family: var(--font-primary); }

.sh-teaser h3 {
  font-size: 13px !important;
  line-height: 1.6;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 600;
  font-style: normal; }

.sh-expandable__header *,
.sh-expandable__trigger * {
  font-family: var(--font-primary); }

.sh-rich-text li {
  list-style: disc; }

.sh-main-menu h2 {
  text-align: left; }

.sh-icon-library__grid__item a .download-text {
  transform: translate3d(-50%, -50%, 0) !important; }

.sh-icon-library__grid__item h3 {
  font-size: rem(10) !important;
  font-weight: normal;
  letter-spacing: 0;
  text-transform: none; }

.sh-resource-download a:hover {
  color: var(--color-white); }

.sh-designsystem main.sh-main--index-page {
  background-color: var(--color-white); }

.sh-main-menu__navigation .third-level a {
  padding-left: 0; }

.sh-main-menu__navigation .second-level .is-current:not(.is-open) > a {
  left: 0;
  text-decoration: underline; }

.sh-main-menu__navigation .third-level .is-current {
  text-decoration: underline; }
